
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Debounce } from 'lodash-decorators'
import { vxm } from '@/store'
import TyreHotelWheelSet from '@/models/tyrehotel/TyreHotelWheelSet'
import TyreHotelWheel from '@/models/tyrehotel/TyreHotelWheel'
import moment from 'moment'
import TyreHotel from '@/models/tyrehotel/TyreHotel'
import TreadDepthThresholds from '@/models/tyre/TreadDepthThresholds'
import TyreHotelImage from '@/models/tyrehotel/TyreHotelImage'

@Component({})
export default class WheelUtils extends Vue {
  @Prop({ type: TyreHotel, required: true })
  private tyreHotel: TyreHotel

  @Prop({ type: TreadDepthThresholds, required: true })
  private treadDepthThresholds: TreadDepthThresholds

  protected isSaving = false
  protected isEditWheels = false

  protected quickSaveTimeout = null
  protected quickSaveTime = null

  protected fullImageDialog = false
  protected fullImage: TyreHotelImage = null
  protected deleteWheelSetDialog = false

  private oldStateTyreHotelWheels: Array<TyreHotelWheel>

  private uploadImageDialogVisible = false
  private uploadImageData = {
    image: null,
    imageWheel: null,
  }

  protected rimMaterialTypes = [
    {
      value: 'Steel',
      text: 'Steel',
    },
    {
      value: 'Aluminium',
      text: 'Aluminium',
    },
  ]

  protected get desktopHeaders(): Array<Record<string, string>> {
    let result = []

    if (!this.isEditWheels) {
      result.push({
        text: 'Image',
        value: 'image',
      })
    }

    result = result.concat([
      {
        text: 'Placement',
        value: 'placement',
      },
      {
        text: 'Tread Depth',
        value: 'treadDepth',
        align: 'center',
      },
      {
        text: 'Dimension',
        value: 'dimension',
      },
      {
        text: 'Tyre Details',
        value: 'tyreDetails',
      },
      {
        text: 'Rim Details',
        value: 'rimDetails',
      },
      {
        text: 'TPMS Details',
        value: 'tpmsDetails',
      },
      {
        text: 'Icons',
        value: 'icons',
      },
      {
        text: 'Comment',
        value: 'comment',
      },
    ])

    return result
  }

  protected getSubTabHeaderClass(season: string): string {
    if (season === 'Summer') {
      return 'orange--text'
    }
    return 'light-blue--text'
  }

  protected getSubTabIcon(season: string): string {
    if (season === 'Summer') {
      return 'fa-sun'
    }
    return 'fa-snowflake'
  }

  protected getSubTabIconColor(season: string): string {
    if (season === 'Summer') {
      return 'orange'
    }
    return 'light-blue'
  }

  protected getSubTabTitle(tyreHotelWheelSet: TyreHotelWheelSet): string {
    const wheelsLocation =
      tyreHotelWheelSet.returnDate === null
        ? this.$t('c:tyre-hotel:wheels in tyre hotel')
        : this.$t('c:tyre-hotel:wheels on car')

    return tyreHotelWheelSet.name + ' ' + wheelsLocation
  }

  protected getTreadDepthBackgroundColor(depth: number): string {
    let critical: number, warning: number

    if (this.tyreHotel.activeTyreHotelWheelSet.season === 'Summer') {
      critical = this.treadDepthThresholds.summer.critical
      warning = this.treadDepthThresholds.summer.warning
    } else {
      critical = this.treadDepthThresholds.winter.critical
      warning = this.treadDepthThresholds.winter.warning
    }

    if (depth === undefined || depth === null) {
      return '#cccccc'
    } else if (depth <= critical) {
      return '#9d261e'
    } else if (depth <= warning) {
      return '#f89408'
    } else {
      return '#45a546'
    }
  }

  protected async updateWheelToDb(tyreHotelWheel: TyreHotelWheel, attributes: Array<string>): Promise<boolean> {
    let result = false

    // in edit mode, do not update real time, only when save is explicitly called
    if (this.isEditWheels) {
      return result
    }

    const data = {}
    attributes.forEach((attribute) => {
      data[attribute] = tyreHotelWheel[attribute]
    })
    await this.$axios
      .patch('/v4/site/tyre-hotels-v2/wheel/' + tyreHotelWheel.id, data)
      .then(() => {
        result = true
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error saving data')
      })

    return result
  }

  protected deleteWheelSet(tyreHotelWheelSet: TyreHotelWheelSet): void {
    this.$axios
      .delete('/v4/site/tyre-hotels-v2/wheel-set/' + tyreHotelWheelSet.id)
      .then(() => {
        this.tyreHotel.tyreHotelWheelSets.splice(this.tyreHotel.tyreHotelWheelSets.indexOf(tyreHotelWheelSet), 1)
        this.deleteWheelSetDialog = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error deleting wheel set')
      })
  }

  protected handleToggle(tyreHotelWheel: TyreHotelWheel, attribute: string, shouldSyncInput: boolean = null): void {
    if (this.tyreHotel?.isReadOnly) {
      return
    }
    tyreHotelWheel[attribute] = !tyreHotelWheel[attribute]
    const newValue = tyreHotelWheel[attribute]

    this.handleChange(tyreHotelWheel, attribute, newValue, shouldSyncInput)
  }

  protected handleInput(tyreHotelWheel: TyreHotelWheel, attribute: string, shouldSyncInput: boolean = null): void {
    if (this.isEditWheels) {
      this.handleNormalInput(tyreHotelWheel, attribute, shouldSyncInput)
    } else {
      this.handleDebouncedInput(tyreHotelWheel, attribute, shouldSyncInput)
    }
  }

  private handleNormalInput(
    tyreHotelWheel: TyreHotelWheel,
    attribute: string,
    shouldSyncInput: boolean = null,
  ): void {
    const newValue = tyreHotelWheel[attribute]

    this.handleChange(tyreHotelWheel, attribute, newValue, shouldSyncInput)
  }

  @Debounce(200)
  private handleDebouncedInput(
    tyreHotelWheel: TyreHotelWheel,
    attribute: string,
    shouldSyncInput: boolean = null,
  ): void {
    this.handleNormalInput(tyreHotelWheel, attribute, shouldSyncInput)
  }

  protected async handleChange(
    tyreHotelWheel: TyreHotelWheel,
    attribute: string,
    newValue: any,
    shouldSyncInput: boolean = null,
  ): Promise<void> {
    const syncedFields = [
      'dimension',
      'tyreBrand',
      'tyreModel',
      'dotMark',
      'rimBrand',
      'rimModel',
      'rimMaterialType',
      'isStudded',
      'hasNitrogen',
      'hasHubCaps',
      'hasWheelLock',
      'hasBolts',
      'isRunFlat',
      'isCTyre',
      'tpmsType',
      'hasTpms',
    ]
    const shouldSync = shouldSyncInput === null ? syncedFields.indexOf(attribute) !== -1 : shouldSyncInput
    let changedWheels: Array<TyreHotelWheel> = []

    if (!shouldSync || this.tyreHotel.activeTyreHotelWheelSet.syncMode === 'NONE') {
      changedWheels.push(tyreHotelWheel)
    } else if (this.tyreHotel.activeTyreHotelWheelSet.syncMode === 'ALL') {
      changedWheels = tyreHotelWheel.placement.includes('LeftFront')
        ? this.tyreHotel.activeTyreHotelWheelSet.tyreHotelWheels
        : [tyreHotelWheel]
    } else if (this.tyreHotel.activeTyreHotelWheelSet.syncMode === 'FRONT_AND_REAR') {
      const isFront = tyreHotelWheel.placement.includes('Front')
      const isLeftFront = tyreHotelWheel.placement.includes('LeftFront')
      const isLeftRear = tyreHotelWheel.placement.includes('LeftRear')

      for (const tyreHotelWheel1 of this.tyreHotel.activeTyreHotelWheelSet.tyreHotelWheels) {
        const isSameType = isFront
          ? isLeftFront && tyreHotelWheel1.placement?.includes('RightFront')
          : isLeftRear && tyreHotelWheel1.placement?.includes('RightRear')

        if (isSameType) {
          changedWheels.push(tyreHotelWheel1)
        }
      }
    }

    const dimensionRegex = /^(\d\d\d)(\d\d)(\d\d)$/
    if (dimensionRegex.test(newValue)) {
      newValue = newValue.replace(dimensionRegex, '$1/$2-$3')
    }

    // These are separated since await takes a bit time, so we first change all in GUI then do in DB, to not give a feeling of delay
    for (const changedWheel of changedWheels) {
      changedWheel[attribute] = newValue
    }
    let successfulChanges = 0
    for (const changedWheel of changedWheels) {
      const result = await this.updateWheelToDb(changedWheel, [attribute])
      if (result) {
        successfulChanges++
      }
    }

    if (!this.isEditWheels && changedWheels.length === successfulChanges) {
      this.quickSaveTime = moment().format('HH:mm:ss')
      if (this.quickSaveTimeout) {
        clearTimeout(this.quickSaveTimeout)
      }
      this.quickSaveTimeout = setTimeout(() => {
        this.quickSaveTime = null
      }, 3000)
    }
  }

  protected openFullImage(image: TyreHotelImage): void {
    this.fullImageDialog = true
    this.fullImage = image
  }

  protected uploadImage(item: TyreHotelWheel): void {
    this.uploadImageData = {
      image: null,
      imageWheel: item,
    }
    this.uploadImageDialogVisible = true
  }

  protected onEditImage(image: TyreHotelImage): void {
    this.uploadImageData = {
      image: image,
      imageWheel: null,
    }
    this.uploadImageDialogVisible = true
  }

  @Watch('tyreHotel.activeTyreHotelWheelSet.syncMode')
  protected onSyncModeChanged(): void {
    const data = {
      syncMode: this.tyreHotel.activeTyreHotelWheelSet.syncMode,
    }

    this.$axios
      .patch('/v4/site/tyre-hotels-v2/wheel-set/' + this.tyreHotel.activeTyreHotelWheelSet.id, data)
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error')
      })
  }

  protected toggleEditMode(): void {
    this.isEditWheels = !this.isEditWheels

    if (this.isEditWheels) {
      this.oldStateTyreHotelWheels = []
      this.tyreHotel.activeTyreHotelWheelSet.tyreHotelWheels.forEach((tyreHotelWheel) => {
        this.oldStateTyreHotelWheels.push(tyreHotelWheel.clone())
      })
    } else {
      this.tyreHotel.activeTyreHotelWheelSet.tyreHotelWheels = this.oldStateTyreHotelWheels
    }
  }

  protected async save(): Promise<void> {
    this.isSaving = true
    let i = 0
    for (const tyreHotelWheel of this.tyreHotel.activeTyreHotelWheelSet.tyreHotelWheels) {
      await this.$axios
        .patch('/v4/site/tyre-hotels-v2/wheel/' + tyreHotelWheel.id, tyreHotelWheel)
        .then(() => {
          i++
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error on saving tyre hotel wheel')
        })
    }
    this.isSaving = false
    if (i === this.tyreHotel.activeTyreHotelWheelSet.tyreHotelWheels.length) {
      this.isEditWheels = false
      vxm.alert.success({
        content: this.$t('c:common:Successfully saved') as string,
        title: this.$t('c:common:Success') as string,
      })
    }
  }

  protected getWheelAttributeStyle(icon: string, danger: boolean): Record<string, unknown> {
    let backgroundColor = ''
    if (danger) {
      backgroundColor = icon ? '#ff4040' : '#cccccc'
    } else {
      backgroundColor = icon ? '#03A9F4' : '#cccccc'
    }
    return {
      backgroundColor: backgroundColor,
      color: icon ? 'white' : '#666666',
      display: 'inline-block',
      textAlign: 'center',
      cursor: 'pointer',
      margin: '0.2em',
      pointerEvents: this.isSaving ? 'none' : 'inherit',
    }
  }
}
