

























































































































































































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import WheelChangeData from '@/components/tyre-hotel/WheelChangeData.vue'
import WheelUtils from '@/components/tyre-hotel/tyre-wheel-data/WheelUtils.vue'
import DocumentIdentifier from '@/models/document/DocumentIdentifier'
import DocumentDialog from '@/components/document/DocumentDialog.vue'
import TyreHotel from '@/models/tyrehotel/TyreHotel'
import WheelSetPositionSelector from '@/components/tyre-hotel/WheelSetPositionSelector.vue'
import NewOrEditImageDialog from '@/components/tyre-hotel/image-data/NewOrEditImageDialog.vue'
import FullImageDialog from '@/components/tyre-hotel/image-data/FullImageDialog.vue'
import ListView from '@/components/list/ListView.vue'

@Component({
  components: {
    ListView,
    FullImageDialog,
    NewOrEditImageDialog,
    DocumentDialog,
    WheelChangeData,
    WheelSetPositionSelector,
  },
  mixins: [WheelUtils],
})
export default class TyreWheelDesktop extends Vue {
  public $refs: Vue['$refs'] & {
    focus: any
  }

  @Prop({ type: TyreHotel, required: true })
  private tyreHotel: TyreHotel

  @Prop({ type: Array, required: true })
  private locations: Array<any>

  private document = null
  private documentDialogVisible = false

  private changePositionsDialog = 0

  private getDotAndAirPressureText(item) {
    const result = []
    if (item.dotMark) {
      result.push(item.dotMark)
    }
    if (item.airPressure) {
      result.push(item.airPressure + ' bar')
    }
    return result.join(', ')
  }

  protected moveFocusToNext(currentWheelIndex: number, inputType: string): void {
    let nextWheelIndex: number
    if (inputType === 'treadDepth') {
      nextWheelIndex = currentWheelIndex + 1
    } else if (inputType === 'airPressure') {
      nextWheelIndex = currentWheelIndex + 1
    }

    if (nextWheelIndex >= this.tyreHotel.activeTyreHotelWheelSet.tyreHotelWheels.length) {
      nextWheelIndex = 0
    }
    const nextInputRef =
      inputType === 'treadDepth' ? 'treadDepthInput' + nextWheelIndex : 'pressureInput' + nextWheelIndex

    const nextInputElement = this.$refs[nextInputRef]

    if (nextInputElement instanceof HTMLElement) {
      nextInputElement.focus()
    }
  }

  private openLabels(): void {
    this.document = new DocumentIdentifier()
    this.document.type = 'TyreHotelWheelSetLabels'
    this.document.params = {
      tyreHotelWheelSetId: this.tyreHotel.activeTyreHotelWheelSet.id,
    }
    this.documentDialogVisible = true
  }

  private openPileLabel(): void {
    this.document = new DocumentIdentifier()
    this.document.type = 'TyreHotelWheelSetPileLabel'
    this.document.params = {
      tyreHotelWheelSetId: this.tyreHotel.activeTyreHotelWheelSet.id,
    }
    this.documentDialogVisible = true
  }

  private changePositions(): void {
    this.changePositionsDialog = new Date().getTime()
  }

  private onSavedPositions(update): void {
    this.changePositionsDialog = 0
    const wheelSet = this.tyreHotel.getTyreHotelWheelById(update.wheelSetId)
    if (!wheelSet) {
      return
    }
    const oldWheels = wheelSet.tyreHotelWheels
    const newWheelsWithPositions = update.positions
    for (let i = 0; i < newWheelsWithPositions.length; i++) {
      const n = newWheelsWithPositions[i]
      for (let j = 0; j < oldWheels.length; j++) {
        const o = oldWheels[j]
        if (o.id === n.wheelId) {
          o.positionSummary = n.positionSummary
        }
      }
    }
    this.tyreHotel.storageLocationId = wheelSet.tyreHotelWheels[0].positionSummary.locationId
  }

  private onComponentChanged(): void {
    this.$emit('onComponentChanged')
  }
}
