




























































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { vxm } from '@/store'
import { DateTime } from 'luxon'

@Component
export default class EventPrints extends Vue {
  @Prop()
  private event

  private scopeShop = 'shop'
  private scopeMachine = 'machine'

  private machineId = ''
  private scope = ''
  private rememberScope = ''
  private savedText = ''
  private isSaving = false

  private isLoading = false
  private isExpanded = false
  private isEnabled = false
  private documents = []
  private printers = []

  private mounted() {
    this.machineId = localStorage.getItem('eventPrintsMachineId') || ''
    this.scope = localStorage.getItem('eventPrintsScope-' + this.event) || ''
    if (!this.scope) {
      this.scope = this.scopeShop
    }
    this.rememberScope = this.scope
    this.load(this.event, this.scope, this.machineId)
  }

  private load(eventType: string, scope: string, machineId: string) {
    this.isLoading = true
    const params =
      '?eventType=' +
      encodeURIComponent(eventType) +
      '&scope=' +
      encodeURIComponent(scope) +
      '&machineId=' +
      encodeURIComponent(machineId)
    this.$axios
      .get('/v4/site/printing/event-prints' + params)
      .then((response) => {
        this.isLoading = false
        this.isEnabled = response.data.data.enabled
        this.documents = response.data.data.documents
        this.printers = response.data.data.printers
      })
      .catch((err) => {
        this.isEnabled = false
        this.isLoading = false
      })
  }

  private addPrinter(doc) {
    doc.printers.push({
      printerId: null,
      copies: 1,
    })
  }

  private getDocLabel(doc) {
    let copies = 0
    for (let i = 0; i < doc.printers.length; i++) {
      copies += parseInt(doc.printers[i].copies) || 0
    }
    return doc.label + ' (' + copies + ' ' + this.$t('c:printing:copies') + ')'
  }

  private get visiblePrinters() {
    const result = []
    if (!this.printers) {
      return result
    }
    for (let i = 0; i < this.printers.length; i++) {
      const p = this.printers[i]
      if (!p.isHidden) {
        result.push(p)
      }
    }
    return result
  }

  private saveSettings() {
    this.$axios
      .post('/v4/site/printing/event-prints', this.getPayload())
      .then((_response) => {
        localStorage.setItem('eventPrintsScope-' + this.event, this.rememberScope)
        this.scope = this.rememberScope
        const dt = DateTime.now()
        this.savedText = this.$t('Saved at') + ' ' + dt.toFormat('HH:mm:ss')
        setTimeout(() => {
          this.savedText = ''
        }, 3000)
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed saving printer settings')
      })
  }

  public getIsEnabled() {
    return this.isEnabled
  }

  public getPayload() {
    return {
      eventType: this.event,
      machineId: this.machineId,
      scope: this.rememberScope,
      documents: this.documents,
    }
  }
}
